import classNames from 'classnames';
import React from 'react';

import PricingFAQ from 'components/shared/price-faq';
import { paddings } from 'constants/spacing';
import MainLayout from 'layouts/main';

const PricingFAQPage = () => (
  <MainLayout headerTheme="grey-5">
    <section className={classNames('pt-20 xl:pt-16 md:pt-10 bg-grey-5', paddings.bottom.lg)}>
      <PricingFAQ className="!mt-0" isSinglePage />;
    </section>
  </MainLayout>
);

export default PricingFAQPage;
